import dayjs from 'dayjs';

import {
  isPartOfEhicNetwork,
  isPartOfEuropeanHealthNetwork,
} from 'util/isPartOfEHN';

import { QuestionId, QuestionnaireState } from 'reducers/questionnaire';
import {
  Employed,
  FinalInformations,
  GeneralInformations,
  InsuranceHistory,
  Other,
  SelfEmployed,
  TaxInformation,
} from 'models';
import { getSalaryType } from 'util/getSalaryType';
import {
  AOK_EMPLOYED_JOB_START_DATE_EMPLOYER_THRESHOLD,
  EMPLOYED_MAX_JOB_START_DATE,
  EMPLOYED_MIN_JOB_START_DATE,
} from 'components/questionnaire/questions/confirmSignedUpByEmployer/constants';
import { Provider, provider } from 'util/environment';
import { isEmployed } from 'util/isEmployed';

export const generalInformationsQuestionOrder = (
  state: QuestionnaireState
): Array<keyof GeneralInformations> => {
  if (state.hasAddressInGermany === false) {
    return [
      'email',
      'name',
      'dateOfBirth',
      'gender',
      'hasAddressInGermany',
      'occupation',
    ];
  }

  return [
    'email',
    'name',
    'dateOfBirth',
    'gender',
    'hasAddressInGermany',
    'address',
    'occupation',
  ];
};

export const getBlockersCheck = (
  state: QuestionnaireState,
  providerName: Provider = 'tk'
): keyof InsuranceHistory | void => {
  const hasPublicInsuranceInGermany =
    state.hasGermanPublicHealthInsurance ||
    (state?.hasPublicHealthInsurance &&
      state?.previousCountryOfInsurance?.code === 'DE');

  const isEmployedOrApprentice =
    isEmployed(state.occupation) ||
    (state.occupation === 'OTHER' && state.otherSituation === 'APPRENTICE');

  const isMiniJob =
    state.annualIncome && getSalaryType(state.annualIncome) === 'MINI_JOB';

  if (
    isMiniJob &&
    state.previousCountryOfInsurance &&
    isPartOfEuropeanHealthNetwork(state.previousCountryOfInsurance) &&
    !hasPublicInsuranceInGermany
  ) {
    return 'miniJobInsuredEU';
  }

  if (isEmployedOrApprentice) {
    const startDate = dayjs().diff(dayjs(state.jobStartDate), 'days');
    if (
      startDate > EMPLOYED_MIN_JOB_START_DATE &&
      hasPublicInsuranceInGermany
    ) {
      return 'switchEmployedProviders';
    }

    if (
      startDate >= EMPLOYED_MAX_JOB_START_DATE &&
      (state.firstTimeEmployedInGermany || !hasPublicInsuranceInGermany)
    ) {
      return 'employerHasAlreadySignedUp';
    }

    if (
      startDate > AOK_EMPLOYED_JOB_START_DATE_EMPLOYER_THRESHOLD &&
      startDate < EMPLOYED_MAX_JOB_START_DATE &&
      providerName === 'aok' &&
      (state.firstTimeEmployedInGermany || !hasPublicInsuranceInGermany)
    ) {
      return 'employerMightHaveAlreadySignedUp';
    }
  }

  if (!isEmployedOrApprentice && hasPublicInsuranceInGermany) {
    return 'switchProviders';
  }
};

export const studentQuestionsOrder = (
  state: QuestionnaireState
): Array<QuestionId> => {
  const questionsOrder: Array<QuestionId> = [
    'universityInGermany',
    'university',
    'worksMoreThanTwentyHours',
    'universityStartDate',
    'hasPublicHealthInsurance',
  ];

  if (!state.hasPublicHealthInsurance) {
    const studiesStartedInThePast = dayjs().isAfter(
      dayjs(state.universityStartDate)
    );

    if (studiesStartedInThePast) {
      questionsOrder.push('expatOrPrivateHealthInsuranceIncoming');
    }

    return questionsOrder;
  }

  const isInsuredInGermany = state?.previousCountryOfInsurance?.code === 'DE';

  questionsOrder.push('previousCountryOfInsurance');

  if (isInsuredInGermany) {
    questionsOrder.push('previousInsurer');

    if (!state.previousInsurer) {
      questionsOrder.push('unlistedPreviousInsurer');
    }
  } else if (isPartOfEhicNetwork(state?.previousCountryOfInsurance)) {
    questionsOrder.push('ehicCard');
  }

  const blockersCheck = getBlockersCheck(state);

  if (blockersCheck) {
    questionsOrder.push(blockersCheck);
  }

  return questionsOrder;
};

export const insuranceHistoryHappyPathQuestionsOrder = (
  state: QuestionnaireState
): Array<keyof InsuranceHistory> => {
  const questionsOrder: Array<keyof InsuranceHistory> = [
    'hasGermanPublicHealthInsurance',
  ];

  if (
    getSalaryType(state?.annualIncome) === 'EXCEED_PRIVATE_THRESHOLD' &&
    state.hasGermanPublicHealthInsurance === false &&
    state.firstTimeEmployedInGermany === false
  ) {
    questionsOrder.push(...highEarnersQuestionsOrder(state));
  } else if (state.hasGermanPublicHealthInsurance) {
    questionsOrder.push('previousInsurer');

    if (!state.previousInsurer) {
      questionsOrder.push('unlistedPreviousInsurer');
    }
  }

  const blockersCheck = getBlockersCheck(state, provider);

  if (blockersCheck) {
    questionsOrder.push(blockersCheck);
  }

  return questionsOrder;
};

export const insuranceHistoryQuestionsOrder = (
  state: QuestionnaireState
): Array<keyof InsuranceHistory> => {
  const age = dayjs().diff(state.dateOfBirth, 'year');
  const oldEmployedUnderPrivateThreshold =
    isEmployed(state.occupation) &&
    age >= 55 &&
    getSalaryType(state?.annualIncome) !== 'EXCEED_PRIVATE_THRESHOLD';
  const insuranceCountry = state?.previousCountryOfInsurance;
  const isPartOfEHN =
    insuranceCountry && isPartOfEuropeanHealthNetwork(insuranceCountry);
  const questionsOrder: Array<keyof InsuranceHistory> = [
    'hasPublicHealthInsurance',
  ];
  if (!state.hasPublicHealthInsurance) {
    if (oldEmployedUnderPrivateThreshold) {
      questionsOrder.push('hadOneDayPublicHealthInsurance');
      if (state.hadOneDayPublicHealthInsurance === false) {
        questionsOrder.push('pastFiveYearsInGermany');
      }
    }
  } else {
    questionsOrder.push('previousCountryOfInsurance');

    if (insuranceCountry?.code === 'DE') {
      questionsOrder.push('previousInsurer');

      if (!state.previousInsurer) {
        questionsOrder.push('unlistedPreviousInsurer');
      }
    } else if (isPartOfEHN && !oldEmployedUnderPrivateThreshold) {
      questionsOrder.push('insuranceTwelveMonths');

      if (state.insuranceTwelveMonths === false) {
        questionsOrder.push('insuranceTwentyFourMonths');
      }
    } else if (!isPartOfEHN && oldEmployedUnderPrivateThreshold) {
      questionsOrder.push('hadOneDayPublicHealthInsurance');

      if (state.hadOneDayPublicHealthInsurance === false) {
        questionsOrder.push('pastFiveYearsInGermany');
      }
    }
  }

  const blockersCheck = getBlockersCheck(state, provider);

  if (blockersCheck) {
    questionsOrder.push(blockersCheck);
  }

  return questionsOrder;
};

export const highEarnersQuestionsOrder = (
  state: QuestionnaireState
): Array<keyof InsuranceHistory> => {
  const questionsOrder: Array<keyof InsuranceHistory> = [
    'currentlyOnPrivate',
    'lastInsuranceInGermany',
    'employmentWithinTwoMonthsOfArrival',
    'lastProviderInGermany',
  ];

  if (!state.lastProviderInGermany) {
    questionsOrder.push('unlistedPreviousInsurer');
  }

  return questionsOrder;
};

export const employedInsuranceHistoryQuestionsOrder = (
  state: QuestionnaireState
): Array<keyof InsuranceHistory> => {
  const incomeType = getSalaryType(state.annualIncome);
  const age = dayjs().diff(state.dateOfBirth, 'year');

  if (
    (incomeType === 'UNDER_PRIVATE_THRESHOLD' && age < 55) ||
    state.firstTimeEmployedInGermany === false ||
    (state.firstTimeEmployedInGermany && age < 55)
  ) {
    return insuranceHistoryHappyPathQuestionsOrder(state);
  }

  return insuranceHistoryQuestionsOrder(state);
};

export const employedQuestionsOrder = (
  state: QuestionnaireState
): Array<QuestionId> => {
  const incomeType = getSalaryType(state.annualIncome);
  const isMiniJob = incomeType === 'MINI_JOB';

  const initialQuestions: Array<keyof Employed> = ['annualIncome'];

  initialQuestions.push(isMiniJob ? 'coverageStartDate' : 'jobStartDate');

  if (incomeType === 'EXCEED_PRIVATE_THRESHOLD') {
    initialQuestions.push('firstTimeEmployedInGermany');
  }

  const questions = [
    ...initialQuestions,
    ...employedInsuranceHistoryQuestionsOrder(state),
  ];

  if (isMiniJob) {
    return questions;
  }

  return [
    ...initialQuestions,
    ...employedInsuranceHistoryQuestionsOrder(state),
    'employerName',
    'jobTitle',
    'companyAddress',
  ];
};

export const otherQuestionOrder = (
  state: QuestionnaireState
): Array<QuestionId> => {
  const questionsOrder: Array<keyof Other> = ['otherSituation'];

  if (state.otherSituation === 'APPRENTICE' || state.otherSituation === 'FSJ') {
    return [
      ...questionsOrder,
      'jobStartDate',
      ...insuranceHistoryHappyPathQuestionsOrder(state),
      'employerName',
      'jobTitle',
      'companyAddress',
    ];
  }

  if (state.otherSituation === 'OTHER') {
    questionsOrder.push('otherSituationDescription');
  }

  questionsOrder.push('coverageStartDate');

  if (state.otherSituation === 'LANGUAGE_SCHOOL') {
    questionsOrder.push('howLongInLanguageSchool');
  }

  return [...questionsOrder, ...insuranceHistoryQuestionsOrder(state)];
};

export const finalInformationsOrder: Array<keyof FinalInformations> = [
  'lastNameAtBirth',
  'placeOfBirth',
  'nationality',
  'receivesPension',
  'hasDependentsToCover',
  'hasDependentChildren',
];

export const taxQuestionsOrder = (
  state: QuestionnaireState
): Array<keyof TaxInformation> => {
  const toReturn: Array<keyof TaxInformation> = [
    'hasTaxNumber',
    'monthlyIncomeForNextYear',
    'agenturFurArbeitBenefits',
    'hasOtherIncome',
  ];

  if (state.hasOtherIncome) {
    toReturn.push('otherIncome', 'otherIncomeMonthlyRevenue');
  }

  return toReturn;
};

export const selfEmployedQuestionsOrder: Array<keyof SelfEmployed> = [
  'coverageStartDate',
];

export const getQuestionOrder = (state: QuestionnaireState): QuestionId[] => {
  const { occupation } = state;
  let order: QuestionId[] = generalInformationsQuestionOrder(state);

  if (occupation) {
    const occupationOrder: QuestionId[] = (() => {
      switch (occupation) {
        case 'UNIVERSITY_STUDENT':
          return studentQuestionsOrder(state);
        case 'EMPLOYED':
        case 'JOB_OFFER':
          if (
            getSalaryType(state.annualIncome) === 'MINI_JOB' &&
            state.previousCountryOfInsurance &&
            isPartOfEuropeanHealthNetwork(state.previousCountryOfInsurance)
          ) {
            return [
              ...employedQuestionsOrder(state),
              ...taxQuestionsOrder(state),
            ];
          }

          return employedQuestionsOrder(state);
        case 'SELF_EMPLOYED':
          return [
            ...selfEmployedQuestionsOrder,
            ...insuranceHistoryQuestionsOrder(state),
            ...taxQuestionsOrder(state),
          ];
        case 'OTHER':
          return [
            ...otherQuestionOrder(state),
            ...(state.otherSituation === 'APPRENTICE' ||
            state.otherSituation === 'FSJ'
              ? []
              : taxQuestionsOrder(state)),
          ];
      }
    })();

    order = [...order, ...occupationOrder, ...finalInformationsOrder];
  }

  return order;
};

// TODO: Optimize this with reselect
export const getIsLastQuestion = (
  state: QuestionnaireState,
  questionId: QuestionId
): boolean => {
  return getQuestionAfter(state, questionId) === undefined;
};

// TODO: Optimize this with reselect
export const getQuestionAfter = (
  state: QuestionnaireState,
  questionId: QuestionId
): QuestionId => {
  const order = getQuestionOrder(state);
  const index = order.findIndex((e) => e === questionId);
  return order[index + 1];
};

export const getQuestionBefore = (
  state: QuestionnaireState,
  questionId: QuestionId
): QuestionId | undefined => {
  const order = getQuestionOrder(state);
  const index = order.findIndex((e) => e === questionId);

  if (index <= 0) {
    return undefined;
  }

  return order[index - 1];
};

export const getNextQuestionId = (state: QuestionnaireState) => {
  const order = getQuestionOrder(state);

  const unansweredQuestion = order.find((e) => {
    return state[e] === undefined;
  });

  return unansweredQuestion;
};

export const getReachableQuestions = (state: QuestionnaireState) => {
  const order = getQuestionOrder(state).slice();

  const notFound = Symbol('Not found');

  const index = order
    .map((e) => (state[e] !== undefined ? e : notFound))
    .indexOf(notFound);

  if (index === -1) {
    return order;
  }

  return order.splice(0, index + 1);
};
